import React from 'react';
import classnames from 'classnames';

import { useSelector } from 'store';
import { useGetHomeMessages } from 'api';
import { Link } from 'components/controls';

import styles from 'theme/components/containers/HomeMessages/HomeMessages.module.scss';

const HomeMessages = () => {
  const { profile } = useSelector((state) => state.auth);

  // Pobranie typów subelementów
  const { data: homeMessagesData } = useGetHomeMessages();

  if (profile?.role === 'ROLE_OPEN_PROFILE' || !homeMessagesData?.items.length) {
    return null;
  }

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Components-Containers-HomeMessages'
      )}>
      <div className={styles.content}>
        {homeMessagesData?.items.map((message, i) => (
          <Link key={i} to={`/${message.link}`} className={styles.item}>
            <div dangerouslySetInnerHTML={{ __html: message.html_code }} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default HomeMessages;
