import React, { FC, useState } from 'react';

import { reduxActions, useDispatch } from 'store';
import { IDynamicUiField, IDictionaryValue } from 'plugins/api/types';
import { Select, FormElement } from 'components/controls';

// typ danych wejściowych
interface IProps {
  field: IDynamicUiField;
  pageSymbol: string;
  componentSymbol: string;
}

const DynamicSelect: FC<IProps> = ({ field, pageSymbol, componentSymbol }) => {
  const dispatch = useDispatch();

  // ustawianie wartości
  const [value, setValue] = useState<string | undefined>(field.value as string);

  const onChangeHandler = (item: IDictionaryValue) => {
    dispatch(
      reduxActions.setFieldValue({
        pageSymbol,
        componentSymbol,
        fieldName: field.field_symbol,
        fieldValue: item.value
      })
    );

    setValue(item.value.toString());
  };

  return (
    <div className="dynamicSelect">
      <FormElement>
        <label>{field.label}</label>
        <Select
          value={value?.toString()}
          options={field.dictionary?.values?.map((value) => ({
            value: value.value.toString(),
            label: value.text,
            item: value
          }))}
          onChange={(item) => item && onChangeHandler(item)}
        />
      </FormElement>
    </div>
  );
};

export default DynamicSelect;
