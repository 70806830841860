import React from 'react';
import { Trans } from 'react-i18next';

import { usePostCartClear } from 'api';

import { Trash2Icon } from 'assets/icons';

import styles from 'theme/components/containers/CartsButton/CartsButton.module.scss';

const ButtonCart = ({
  cartId,
  refetchCartsData
}: {
  cartId: number;
  refetchCartsData: () => void;
}) => {
  const { mutate: clearCart } = usePostCartClear(cartId, {
    onSuccess: () => {
      refetchCartsData();
    }
  });

  return (
    <div className={styles.trash} onClick={() => clearCart()}>
      <Trash2Icon />
      <Trans>Wyczyść</Trans>
    </div>
  );
};

export default ButtonCart;
