// komponent modal'a

import React, { PropsWithChildren, FC } from 'react';
import MuiModal from '@mui/material/Modal';
import { X } from 'react-bootstrap-icons';
import classnames from 'classnames';

import styles from 'theme/components/controls/Modal/Modal.module.scss';

// typ danych wejściowych
interface IProps {
  title: string;
  fullScreen?: boolean;
  fullWidth?: boolean;
  onClose?: () => void;
  onMouseHover?: (arg: boolean) => void;
  color?: 'WHITE';
  customWidth?: number;
}

const Modal: FC<PropsWithChildren<IProps>> = ({
  title,
  onClose,
  onMouseHover,
  children,
  fullScreen,
  fullWidth,
  color,
  customWidth
}) => {
  return (
    <MuiModal open className={'StylePath-Components-Controls-Modal'}>
      <div
        className={classnames(
          styles.wrapperComponent,
          { [styles.fullScreen]: fullScreen },
          { [styles.fullWidth]: fullWidth },
          { [styles.colorWhite]: color === 'WHITE' }
        )}
        onMouseLeave={() => onMouseHover?.(false)}
        onMouseEnter={() => onMouseHover?.(true)}
        style={{ maxWidth: customWidth ? `${customWidth}px` : 'unset', width: '100%' }}>
        {(title || onClose) && (
          <div className={styles.header}>
            <div className={styles.title}> {title} </div>
            {!!onClose && (
              <div className={styles.close}>
                <X onClick={onClose} />
              </div>
            )}
          </div>
        )}

        <div> {children}</div>
      </div>
    </MuiModal>
  );
};

export default Modal;
