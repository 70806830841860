/* eslint-disable @typescript-eslint/no-unused-vars */
// strona listy produktów

import React, { FC, useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import qs from 'query-string';
import { Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { ChevronLeft, Funnel } from 'react-bootstrap-icons';

import { reduxActions, useDispatch, useSelector } from 'store';
import {
  useGetProducts,
  useGetProductsFiltersAdditional,
  useGetProductsFiltersMain,
  useGetProductsSortMethods,
  useGetProductsTitle,
  useGetCmsSectionArticle,
  useGetProductsCategorySubcategories
} from 'api';
import { IProductsRequest, IProductsSortMethod } from 'api/types';
import { useAppNavigate, useRWD, useScrollDown } from 'hooks';
import {
  FiltersSidebar,
  FiltersTopBar,
  FiltersOverlay,
  Subcategories,
  CatalogImage
} from './components';
import {
  MobileProductItem,
  ProductItem,
  Search,
  HidePrices,
  Categories
} from 'components/containers';
import { Breadcrumbs, Container, Loader, Pagination, Select } from 'components/controls';
import GridSwitcher, { IProps as IGridSwitcherProps } from 'components/controls/GridSwitcher';

import styles from 'theme/pages/Products/Products.module.scss';
import { title } from 'process';

interface IFilter {
  filter_id: string;
  filter_value: string;
}

// typ danych wejściowych
interface IProps {
  categoryId?: string;
}

const Products: FC<IProps> = ({ categoryId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useAppNavigate();
  const location = useLocation();

  const getPosition = (string: string, subString: string, index: number) => {
    return string.split(subString, index).join(subString).length;
  };

  const urlPrefix = location.pathname.slice(0, getPosition(location.pathname, '/', 2));

  const { isMobile, isTablet } = useRWD();

  const { isScrollDown } = useScrollDown();

  // kategoria pochodząca z url resolvera
  const urlResolverCategoryId = parseInt(categoryId || '0');
  const categoryIdQueryParam = urlResolverCategoryId ? { category_id: urlResolverCategoryId } : {};

  // globalnie ustawiona kategoria i fraza wyszukiwarki
  const { categoryId: globalCategoryId, searchKeyword: globalSearchKeyword } = useSelector(
    (state) => state.products
  );

  // Aktualne filtry
  const [queryFilters, setQueryFilters] = useState<IFilter[]>([]);

  const [isFilterMobile, setIsFilterMobile] = useState(false);

  // Widok listy (boxy/linie)
  const [gridType, setGridType] = useState<IGridSwitcherProps['type']>('grid');

  // Parametry zapytania do API
  const [productsQuery, setProductsQuery] = useState<IProductsRequest>({
    page: 1,
    limit: 20,
    filter_attributes: '',
    mode: undefined,
    search_keyword: '',
    ...categoryIdQueryParam,
    ...qs.parseUrl(window.location.href, { parseNumbers: true }).query
  });

  // pobranie listy filtrów
  const { data: mainFiltersData, isLoading: isMainFiltersLoading } = useGetProductsFiltersMain({
    ...productsQuery,
    page: 1,
    limit: 999
  });

  // pobranie listy filtrów
  const { data: additionalFiltersData, isLoading: isAdditionalFiltersLoading } =
    useGetProductsFiltersAdditional(
      { ...productsQuery, page: 1, limit: 999 },
      {
        enabled: !!globalCategoryId
      }
    );

  // Pobranie listy produktów
  const { data: productsData, isLoading: isLoadingProducts } = useGetProducts(productsQuery);

  // Pobranie nagłówka
  const { data: productsTitleData } = useGetProductsTitle(productsQuery);

  const { data: subacategories, refetch: refetchSubcategories } =
    useGetProductsCategorySubcategories(urlResolverCategoryId, {}, { enabled: false });

  // Pobranie opcji sortowania
  const { data: productsSortingMethodsData } = useGetProductsSortMethods({
    page: 1,
    limit: 999
  });

  const { data: seoDescriptionData, refetch: refetchSeoDescription } = useGetCmsSectionArticle(
    'CATEGORY_DESCRIPTION',
    globalCategoryId?.toString() || '',
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (productsTitleData?.type === 'katalog') {
      refetchSubcategories();
    }
  }, [productsTitleData?.type]);

  useEffect(() => {
    if (globalCategoryId) {
      refetchSeoDescription();
    }
  }, [globalCategoryId]);

  // Zmiana url'a przy zmianie parametrów zapytania do API
  useEffect(() => {
    const { category_id, ...restProductsQuery } = productsQuery;
    navigate(
      `${location.pathname.replace(urlPrefix, '')}?${qs.stringify(restProductsQuery, {
        skipEmptyString: true
      })}`
    );
  }, [productsQuery]);

  // Ustawienie aktywnych filtrów z url'a (podczas wejścia na stronę)
  useEffect(() => {
    typeof productsQuery.category_id !== 'undefined' &&
      dispatch(reduxActions.setCategoryId(productsQuery.category_id));
    typeof productsQuery.search_keyword === 'string' &&
      dispatch(reduxActions.setSearchKeyword(productsQuery.search_keyword));
    setQueryFilters(
      productsQuery.filter_attributes
        ?.split('|')
        .filter((item) => item)
        .map((queryFilter) => {
          const queryFilterArray = queryFilter.split('=');
          return {
            filter_id: queryFilterArray[0],
            filter_value: queryFilterArray[1]
          };
        }) || []
    );

    return () => {
      dispatch(reduxActions.setCategoryId());
    };
  }, []);

  // Ustawienie breadcrumbs'ów (przy renderowaniu strony)
  useEffect(() => {
    dispatch(
      reduxActions.setBreadcrumbs([
        {
          name: t('Produkty')
        }
      ])
    );
  }, []);

  // aktualizacja parametrów zapytania przy zmianie kategorii
  useEffect(() => {
    setProductsQuery((prevState) => ({
      ...prevState,
      category_id: globalCategoryId,
      page: 1
    }));
  }, [globalCategoryId]);

  // aktualizacja parametrów zapytania przy zmianie frazy wyszukiwania
  useEffect(() => {
    setProductsQuery((prevState) => ({
      ...prevState,
      search_keyword: globalSearchKeyword,
      page: 1
    }));
  }, [globalSearchKeyword]);

  // Uaktualnienie filtrów w zapytaniu do API
  useEffect(() => {
    setProductsQuery((prevState) => ({
      ...prevState,
      filter_attributes: queryFilters
        .map((filter) => `${filter.filter_id}=${filter.filter_value}`)
        .join('|')
    }));
  }, [queryFilters]);

  // wyświetlanie kategorii może być po id z urlResolvera albo bezpośrednia z query param category_id
  const urlCategoryId =
    urlResolverCategoryId || qs.parseUrl(location.search, { parseNumbers: true }).query.category_id;

  // Uaktualnienie kategorii (w globalnym stanie) po zmianie tej danej w url'u
  useEffect(() => {
    urlCategoryId && dispatch(reduxActions.setCategoryId(Number(urlCategoryId)));
  }, [urlCategoryId]);

  const urlSearchKeyword = qs.parseUrl(location.search).query.search_keyword;
  // Uaktualnienie frazy wyszukwania (w globalnym stanie) po zmianie tej danej w url'u
  useEffect(() => {
    typeof urlSearchKeyword === 'string' &&
      dispatch(reduxActions.setSearchKeyword(urlSearchKeyword));
  }, [urlSearchKeyword]);

  const urlModeParameter = qs.parseUrl(location.search, { parseNumbers: true }).query.mode;
  // Uaktualnienie kategorii (w globalnym stanie) po zmianie tej danej w url'u
  useEffect(() => {
    if (urlModeParameter) {
      setProductsQuery(() => ({
        page: 1,
        limit: 20,
        mode: urlModeParameter as IProductsRequest['mode']
      }));
    }
  }, [urlModeParameter]);

  // Funkcja aktualizująa filtry (w stanie komponentu i zapytaniu do API)
  const updateQueryFilters = (filters: IFilter[]) => {
    setQueryFilters(filters);
    setProductsQuery((prevState) => ({
      ...prevState,
      filter_attributes: filters
        .map((filter) => `${filter.filter_id}=${filter.filter_value}`)
        .join('|'),
      page: 1
    }));
  };

  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-Products')}>
      {productsTitleData && (
        <Helmet>
          <title>{productsTitleData.name}</title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      )}
      <Breadcrumbs />

      {isFilterMobile ? (
        isMobile && (
          <FiltersOverlay
            categoryId={globalCategoryId || undefined}
            onChange={updateQueryFilters}
            queryFilters={queryFilters}
            filtersData={[
              ...(mainFiltersData?.items || []),
              ...(additionalFiltersData?.items || [])
            ]}
            close={() => setIsFilterMobile(false)}
            productsSortingMethodsData={productsSortingMethodsData}
            productsQuery={productsQuery}
            setSort={(id: string) =>
              setProductsQuery({
                ...productsQuery,
                sort_method: id,
                page: 1
              })
            }
          />
        )
      ) : (
        <Container>
          <div className={styles.content}>
            {!isMobile && (
              <div className={styles.categories}>
                <span className={styles.Title}>{t('Kategorie')}</span>

                <Categories
                  searchKeyword={globalSearchKeyword}
                  onCategoryClick={(cat) =>
                    dispatch(
                      reduxActions.setCategoryId(cat.id === globalCategoryId ? undefined : cat.id)
                    )
                  }
                  chosenCategoryId={globalCategoryId || undefined}
                  productsQueryParams={productsQuery}
                />

                {isAdditionalFiltersLoading && <Loader />}
                <FiltersSidebar
                  categoryId={globalCategoryId || undefined}
                  onChange={updateQueryFilters}
                  queryFilters={queryFilters}
                  filtersData={additionalFiltersData?.items}
                />
              </div>
            )}

            <div className={styles.list}>
              <div className={styles.searchWrapper}>
                <Search />
              </div>

              <div
                className={classnames(styles.bottomBar, { [styles.isScrollDown]: !isScrollDown })}>
                {productsTitleData?.type === 'katalog' && (
                  <CatalogImage categoryId={urlResolverCategoryId} />
                )}
                <div className={styles.title}>
                  {productsTitleData && (
                    <>
                      <Link className={styles.arrow} to="/">
                        <ChevronLeft />
                      </Link>{' '}
                      <h1>{productsTitleData.name}</h1>{' '}
                      <span>({productsTitleData.products_count})</span>
                    </>
                  )}
                </div>
                {productsTitleData?.type === 'katalog' && (
                  <Subcategories categories={subacategories?.items} />
                )}

                {seoDescriptionData?.article_fields?.[0]?.value && (
                  <h2
                    className={styles.seoBlock}
                    dangerouslySetInnerHTML={{
                      __html: seoDescriptionData?.article_fields?.[0].value
                    }}
                  />
                )}
                <div className={styles.filterWrapper}>
                  <div className={styles.filterInfo}>
                    <span>
                      <Trans>Wyniki wyszukiwania</Trans>
                    </span>
                    <span>({productsData?.total_count})</span>
                  </div>

                  <div onClick={() => setIsFilterMobile(true)} className={styles.filterButton}>
                    <Funnel />{' '}
                    <div>
                      <Trans>Filtruj</Trans>/<Trans>Sortuj</Trans>
                    </div>
                    <div className={styles.filterBox}>0</div>
                  </div>
                </div>
              </div>

              {!isMobile && (
                <Grid item sm={12}>
                  <div className={styles.actionsTopBar}>
                    {isMainFiltersLoading && <Loader />}
                    <FiltersTopBar
                      onChange={updateQueryFilters}
                      queryFilters={queryFilters}
                      filtersData={mainFiltersData?.items}
                    />
                    <div>
                      <HidePrices />
                      <div className={styles.sortingSelectWrapper}>
                        <Select<IProductsSortMethod>
                          onChange={(sortMethod) =>
                            sortMethod &&
                            setProductsQuery({
                              ...productsQuery,
                              sort_method: sortMethod.id,
                              page: 1
                            })
                          }
                          value={productsQuery.sort_method}
                          options={
                            productsSortingMethodsData?.items.map((item) => ({
                              value: item.id,
                              label: item.name,
                              item
                            })) || []
                          }
                          placeholder={t('Sortowanie')}
                        />
                      </div>

                      <GridSwitcher type={gridType} onChange={setGridType} />
                    </div>
                  </div>
                </Grid>
              )}

              <Grid
                container
                columnSpacing="12px"
                rowSpacing="12px"
                itemScope
                itemType="http://schema.org/ItemList">
                {productsData?.items.map((product) => (
                  <Grid
                    key={product.id}
                    item
                    md={gridType === 'line' ? 12 : isTablet ? 4 : 3}
                    itemProp="itemListElement"
                    itemScope
                    itemType="http://schema.org/ListItem">
                    {isMobile ? (
                      <MobileProductItem
                        product={product}
                        categoryId={globalCategoryId}
                        searchKeywords={globalSearchKeyword}
                        hidePricesEnabled
                      />
                    ) : (
                      <ProductItem
                        product={product}
                        line={gridType === 'line'}
                        categoryId={globalCategoryId}
                        searchKeywords={globalSearchKeyword}
                        hidePricesEnabled
                        showProductPreview
                      />
                    )}
                  </Grid>
                ))}

                {isLoadingProducts && (
                  <Grid item sm={12} className={styles.loadingWrapper}>
                    <Loader />
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
          <Pagination
            pagesCount={productsData?.total_pages || 1}
            page={productsQuery.page || 1}
            onChange={(page) => setProductsQuery({ ...productsQuery, page })}
          />
          {seoDescriptionData?.article_fields?.[1]?.value && (
            <h2
              className={styles.seoBlock}
              dangerouslySetInnerHTML={{ __html: seoDescriptionData?.article_fields?.[1].value }}
            />
          )}
        </Container>
      )}
    </div>
  );
};

export default Products;
