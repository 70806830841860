// komponent Checkbox
import React, { FC } from 'react';
import { Checkbox as CheckboxMui } from '@mui/material';
import classnames from 'classnames';

import styles from 'theme/components/controls/Checkbox/Checkbox.module.scss';

// typ danych wejściowych
interface IProps {
  checked: boolean;
  indeterminate?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Checkbox: FC<IProps> = ({ checked, onClick, indeterminate }) => (
  <span className={classnames(styles.wrapperComponent, 'StylePath-Components-Controls-Checkbox')}>
    <CheckboxMui
      className={classnames(
        styles.checkbox,
        {
          [styles.checked]: checked,
          [styles.indeterminate]: indeterminate
        }
      )}
      checked={checked}
      indeterminate={indeterminate}
      onClick={onClick}
    />
  </span>
);

export default Checkbox;
