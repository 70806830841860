// szczegóły produktu

import React, { FC } from 'react';
import { Grid } from '@mui/material';

import { IProductCategoryCatalogsItem } from 'api/types';

import { Link } from 'components/controls';

import styles from 'theme/pages/Product/components/Catalogs/Catalogs.module.scss';

// typ danych wejściowych
interface IProps {
  catalogs: IProductCategoryCatalogsItem[];
}

const Catalogs: FC<IProps> = ({ catalogs }) => {
  return (
    <div className={styles.wrapperComponent}>
      <Grid container columnSpacing={4} rowSpacing={2}>
        {catalogs.map((catalog, i) => (
          <Grid key={i} item xs={6} md={3} lg={2}>
            <Link to={`/${catalog.url_link}`}>
              <img className={styles.image} src={catalog.images.normal} />
              <div className={styles.name}>{catalog.name}</div>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Catalogs;
