/* eslint-disable @typescript-eslint/no-explicit-any */
// Hook odpowiedzialny za tworzenie listy zakupowej

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// parametry requestu do api
interface IRequest {
  product_id: number;
  unit_id: number;
  quantity?: number;
  type: 'FAVORITES' | 'NOT_AVAILABLE_PRODUCTS';
}

// typ zwracanych danych
type IResponse = ICommandResponseSuccess & {
  data: {
    id: number;
  };
};

const postShoppingListAddToDefaultList = (data: IRequest): Promise<IResponse> =>
  axios.post('/shopping-lists/add-to-default-shopping-list', data);

export const usePostShoppingListAddToDefaultList = (
  options?: UseMutationOptions<IResponse, IError, IRequest>
) => useMutation((data: IRequest) => postShoppingListAddToDefaultList(data), options);
