// Hook odpowiedzialny za pobranie szczegółów klienta

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';

// typ zwracanych danych
export interface IResponse {
  client_id: number;
  id: number;
  name: string;
  street: string;
  postal_code: string;
  city: string;
  country: string;
  nip: string;
  apartment: string;
  building: string;
  contact_person_first_name: string;
  contact_person_last_name: string;
  contact_person_email: string;
  contact_person_phone: string;
}

const getClient = (id: number): Promise<IResponse> => axios.get(`/clients/${id}`);

export const useGetClient = (id: number, options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>(['client', id], () => getClient(id), options);
