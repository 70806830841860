// Hook odpowiedzialny za pobranie listy wiadomości na stronie głównej

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError, IPaginationResponse } from 'api/types';

export interface IHomeMessagesListItem {
  section: string;
  link: string;
  html_code: string;
  message_style: 'success';
}

// typ zwracanych danych
type IResponse = IPaginationResponse<IHomeMessagesListItem>;

const getHomeMessages = (): Promise<IResponse> => axios.get('/home/messages');

export const useGetHomeMessages = (options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>(['home-messages'], () => getHomeMessages(), options);
