// sekcja demo na stronie głównej

import React from 'react';
import classnames from 'classnames';
import { Trans } from 'react-i18next';

import { reduxActions, useDispatch, useSelector } from 'store';
import { Checkbox } from 'components/controls';

import styles from 'theme/components/containers/HidePrices/HidePrices.module.scss';

export const HidePrices = () => {
  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.auth);
  const { hidePrices } = useSelector((state) => state.products);

  const handleClick = () => {
    if (localStorage.getItem('hidePrices')) {
      localStorage.removeItem('hidePrices');
      dispatch(reduxActions.setHidePrices(false));
      return;
    }

    localStorage.setItem('hidePrices', 'true');
    dispatch(reduxActions.setHidePrices(true));
  };

  if (profile?.role === 'ROLE_OPEN_PROFILE') {
    return null;
  }

  return (
    <div
      className={classnames(styles.wrapperComponent, 'StylePath-Components-Containers-HidePrices')}>
      <Checkbox onClick={() => handleClick()} checked={!!hidePrices} />
      <Trans>Ukryj ceny</Trans>
    </div>
  );
};

export default HidePrices;
