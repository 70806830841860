/* eslint-disable @typescript-eslint/no-explicit-any */
// Hook odpowiedzialny za tworzenie koszyka

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// typ zwracanych danych
interface IResponse extends ICommandResponseSuccess {
  data: {
    id: number;
  };
}

const postCartClear = (cartId: number): Promise<IResponse> => axios.post(`/carts/${cartId}/clear`);

export const usePostCartClear = (cartId: number, options?: UseMutationOptions<IResponse, IError>) =>
  useMutation(() => postCartClear(cartId), options);
