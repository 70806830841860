// Hook odpowiedzialny za pobranie gradcrumbs'ów produktu

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  ICommandResponseError as IError,
  IPaginationRequest,
  IPaginationResponse
} from 'api/types';

export type IRequest = IPaginationRequest;

// typ zwracanych danych
export interface IProductCategoryCatalogsItem {
  id: number;
  name: string;
  images: {
    normal: string;
  };
  url_link: string;
}

// typ zwracanych danych
type IResponse = IPaginationResponse<IProductCategoryCatalogsItem>;

const getProductCategoryCatalogs = (productId: number, params?: IRequest): Promise<IResponse> =>
  axios.get(`/products/${productId}/category-catalogs`, { params });

export const useGetProductCategoryCatalogs = (
  productId: number,
  params?: IRequest,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    ['category-catalogs', productId, params],
    () => getProductCategoryCatalogs(productId, params),
    options
  );
