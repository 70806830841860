// Hook odpowiedzialny za pobranie szczegłów produktu

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';

export type IImiage = {
  alt: string;
  big: string;
  is_main: boolean;
  min: string;
  name: string;
  source: string;
  thumb: string;
  type: 'NORMAL' | 'TABLE';
};

// typ zwracanych danych
export interface IResponse {
  symbol: string;
  name: string;
  description: string;
  type: string;
  images: IImiage[];
}

const getProductsCategory = (id: number): Promise<IResponse> =>
  axios.get(`/products/categories/${id}`);

export const useGetProductsCategory = (id: number, options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>(['product', id], () => getProductsCategory(id), options);
