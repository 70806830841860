import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAppNavigate } from 'hooks';
import { useGetAuthOverloginUsers, usePostOverLogin } from 'api';
import { useDispatch, reduxActions, useSelector } from 'store';
import { DropDown } from 'components/controls';

import styles from 'theme/components/layouts/MainLayout/components/HeaderTopBar/HeaderTopBar.module.scss';

const OverLoginUsers = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { profile } = useSelector((state) => state.auth);
  const navigate = useAppNavigate();
  const { pathname } = useLocation();

  // pobranie listy użytkowników możliwych do przelogownania
  const { data: overloginUsersData, refetch: refetchOverloginUsers } = useGetAuthOverloginUsers(
    {},
    { enabled: false }
  );

  // przelogowanie na wybranego użytkownika
  const { mutateAsync: postOverLogin } = usePostOverLogin();

  useEffect(() => {
    if (profile?.role !== 'ROLE_OPEN_PROFILE') {
      refetchOverloginUsers();
    }
  }, []);

  const handleOverLogin = async (id: number) => {
    await postOverLogin({ to_switch_user_id: id });

    if (pathname.includes('/cart/')) {
      navigate('/cart/0');
    }

    dispatch(reduxActions.setCurrentCartId(null));
    dispatch(reduxActions.setOverlogin(id));
  };

  return (
    <div className={styles.overloginWrapper}>
      <DropDown
        label={t('Zaloguj jako')}
        items={
          overloginUsersData?.items.reduce(
            (acc, item) => [
              ...acc,
              {
                label: item.name,
                disabled: true
              },
              ...item.users_list.map((user) => ({
                label: <span style={{ paddingLeft: 16 }}>{user.name}</span>,
                onClick: () => handleOverLogin(user.id)
              }))
            ],
            [] as {
              label: ReactNode;
              onClick?: () => void;
              disabled?: boolean;
            }[]
          ) || []
        }
      />
    </div>
  );
};

export default OverLoginUsers;
