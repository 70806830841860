import React, { FC } from 'react';
import classnames from 'classnames';

import { IProductLabel } from 'api/types';

import { MirrorIcon, TruckIcon, TapeIcon } from 'assets/icons';

import styles from 'theme/components/controls/Label/Label.module.scss';

interface IProps {
  label: IProductLabel;
}

const Label: FC<IProps> = ({ label }) => {
  const renderIcon = () => {
    if (label.type === 'X_DODATKOWYKOSZT') {
      return <TruckIcon />;
    }

    if (label.type === 'X_GABARYT') {
      return <TapeIcon />;
    }

    if (label.type === 'X_SZYBA') {
      return <MirrorIcon />;
    }

    return null;
  };

  return (
    <span
      className={classnames(
        styles.wrapperComponent,
        styles[label.type.toLowerCase()],
        'StylePath-Components-Controls-Label'
      )}>
      {renderIcon()}
      {label.name}
    </span>
  );
};

export default Label;
