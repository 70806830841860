// lista kategorii

import React, { FC, useEffect, useState, useMemo } from 'react';
import Dropdown from 'react-multilevel-dropdown';
import classnames from 'classnames';
import { Trans } from 'react-i18next';

import { useGetProductsCategories, useGetLayoutCategories } from 'api';
import { ICategoryListItem, IProductsCategoriesRequest, IProductsRequest } from 'api/types';
import { Loader, Link } from 'components/controls';

import { ArrowLongIcon } from 'assets/icons';

import styles from 'theme/components/containers/Categories/Categories.module.scss';

// typ danych wejściowych
interface Props {
  onCategoryClick?: (category: ICategoryListItem) => void;
  chosenCategoryId?: number;
  searchKeyword?: string;
  productsQueryParams?: IProductsRequest;
  isHomePage?: boolean;
}

const Categories: FC<Props> = ({ onCategoryClick, isHomePage, productsQueryParams = {} }) => {
  // parametry zapytania do api
  const [categoriesQuery, setCategoriesQuery] = useState<IProductsCategoriesRequest>({
    ...productsQueryParams,
    page: 1,
    limit: 999,
    search_keyword: ''
  });

  // pobranie listy kategorii na listingach kategorii
  const {
    data: productsCategoriesData,
    refetch: refetchProductsCategories,
    isLoading: isProductsCategoriesLoading
  } = useGetProductsCategories(categoriesQuery, { enabled: false });

  // pobranie listy kategorii na listingach kategorii
  const {
    data: layoutCategoriesData,
    refetch: refetchLayoutCategories,
    isLoading: isLayoutCategoriesLoading
  } = useGetLayoutCategories(categoriesQuery, { enabled: false });

  useEffect(() => {
    if (isHomePage) {
      refetchLayoutCategories();
      return;
    }

    refetchProductsCategories();
  }, [isHomePage, categoriesQuery]);

  // kategorie layout
  const layoutCategories = useMemo(() => {
    return layoutCategoriesData?.items.find((category) => !!category.show_category_button)
      ?.categories_list;
  }, [layoutCategoriesData]);

  // kategorie products
  const productsCategories = useMemo(() => {
    return productsCategoriesData?.items;
  }, [productsCategoriesData]);

  // aktualizacja parametrów zapytania do api przy zmianie parametrów zapytaqnia o produkty
  // useEffect(() => {
  //   setCategoriesQuery({
  //     ...productsQueryParams,
  //     page: 1,
  //     limit: 999
  //   });
  // }, [productsQueryParams]);

  const renderFirstLevel = (category: ICategoryListItem, i: number, level = 0) => {
    return (
      <Dropdown.Item
        key={i}
        className={classnames(styles.category, { [styles.main]: level === 0 })}>
        <Link to={`/${category.url_link}`} onClick={() => onCategoryClick?.(category)}>
          {category.name}
        </Link>
        {category.subcategories_total_count > 0 && <ArrowLongIcon />}
        {!!category.subcategories.length && (
          <Dropdown.Submenu position="right" className={styles.subMenu}>
            <Dropdown.Item className={styles.image}>
              <img src={category?.images?.menu_icon?.[0]?.source || ''} />
            </Dropdown.Item>

            {category.subcategories.map((subcategory, i) =>
              renderFirstLevel(subcategory, i, (level = level + 1))
            )}
          </Dropdown.Submenu>
        )}
      </Dropdown.Item>
    );
  };

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Pages-Products-components-Categories'
      )}>
      {(isProductsCategoriesLoading || isLayoutCategoriesLoading) && <Loader />}
      {(productsCategories?.length || layoutCategories?.length) && (
        <div>
          <div className={styles.label}>
            <Trans>Kategorie</Trans>
          </div>
          {(isHomePage ? layoutCategories : productsCategories)?.map((category, i) =>
            renderFirstLevel(category, i)
          )}
        </div>
      )}
    </div>
  );
};

export default Categories;
