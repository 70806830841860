// selektor daty

import React, { FC, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { parseISO, format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import Popover from '@mui/material/Popover';
import { Calendar } from 'react-bootstrap-icons';
import classnames from 'classnames';

import styles from 'theme/components/controls/DatePicker/DatePicker.module.scss';
import 'react-day-picker/dist/style.css';

// typ danych wejściowych
interface IProps {
  date?: string;
  onChange?: (date: string) => void;
}

const DatePicker: FC<IProps> = ({ date, onChange }) => {
  // lokalna data
  const [localDate, setLocalDate] = useState<Date>();

  // element html z kalendarzami
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);

  // ustawienie lokalnej daty przy zmianie danych z props'ów
  useEffect(() => {
    setDateFromProps();
  }, [date]);

  // funkcja ustawiająca lokalnej daty na dane z propsów
  const setDateFromProps = () => {
    setLocalDate(date ? parseISO(date) : undefined);
  };

  // fonkcja wykonywana po wybraniu daty
  const handleOnSelectDate = (selectedDate: Date) => {
    setLocalDate(selectedDate);
    setPopoverAnchor(null);
    onChange?.(format(selectedDate, 'yyyy-MM-dd'));
  };

  const handleOnClosePopover = () => {
    setPopoverAnchor(null);
    setDateFromProps();
  };

  return (
    <>
      <button
        className={classnames(styles.button, 'StylePath-Components-Controls-DatePicker')}
        onClick={(event) => setPopoverAnchor(event.currentTarget)}>
        {localDate ? (
          <span>{format(localDate, 'dd.MM.yyyy')}</span>
        ) : (
          <span className={styles.placeholder}>
            <Trans>Wybierz datę</Trans>
          </span>
        )}
        <Calendar />
      </button>

      <Popover
        anchorEl={popoverAnchor}
        open={!!popoverAnchor}
        onClose={handleOnClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <DayPicker
          selected={localDate}
          onDayClick={(date?: Date) => date && handleOnSelectDate(date)}
        />
      </Popover>
    </>
  );
};

export default DatePicker;
