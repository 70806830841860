/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, Dispatch, SetStateAction } from 'react';
import qs from 'query-string';
import mapValues from 'lodash/mapValues';

import { useAppNavigate } from 'hooks';
import { useCallCommand, useDelete } from 'plugins/api';
import { IDynamicAction } from 'plugins/api/types';
import { loadDataUrlParser } from 'plugins/util/parser';

import 'plugins/theme/components/TablePanel/TablePanel.scss';

// typ danych wejściowych
interface IProps {
  additionalAction: IDynamicAction;
  item: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  triggerDelete: boolean;
  setTriggerDelete: Dispatch<SetStateAction<boolean>>;
}

type IParamsList = { id?: string; mode?: string };

const AdditionalActionCell: FC<IProps> = ({
  additionalAction,
  item,
  setOpen,
  triggerDelete,
  setTriggerDelete
}) => {
  const navigate = useAppNavigate();

  const endpointUrl = loadDataUrlParser(additionalAction.method_params?.command_url || '', item);

  // lista parametrów
  const { id, mode, ...restParams }: IParamsList = qs.parse(
    additionalAction.method_params?.params_list?.replaceAll(';', '&') || ''
  );

  // poprawenie wartości parametru id
  const propsId = item[id?.replace(':', '') || ''];

  // lista propsów
  const paramsProps = mode === 'EDIT' ? { id: parseInt(propsId) } : {};

  // parsowanie parametrów do obiektu
  const paramsObject = mapValues(
    restParams,
    (param: string) => item[param?.replace(':', '') || '']
  );

  // parsowanie parametrów do urla
  const paramsUrl = qs.stringify({ ...paramsObject, mode });

  // wywołanie metody CallCommand
  const { mutate: callCommand } = useCallCommand(endpointUrl || '');

  // wywołanie metody Delete
  const { mutate: deleteAction } = useDelete(endpointUrl || '', {
    onSuccess: () => {
      setTriggerDelete(false);
    },
    onError: () => {
      setTriggerDelete(false);
    }
  });

  useEffect(() => {
    if (triggerDelete) {
      deleteAction({});
    }
  }, [triggerDelete]);

  const handleActionClick = (additionalAction: IDynamicAction) => {
    // obsłużenie akcji CallCommand
    if (additionalAction.method === 'CallCommand') {
      callCommand({});
    }

    // obsłużenie akcji Delete
    if (additionalAction.method === 'Delete') {
      deleteAction({});
      // setOpen(true);
    }

    if (additionalAction.method === 'Redirect') {
      additionalAction.method_params?.page_url &&
        navigate(additionalAction.method_params.page_url + (paramsUrl ? `?${paramsUrl}` : ''));
    }
  };

  return <div onClick={() => handleActionClick(additionalAction)}>{additionalAction.label}</div>;
};

export default AdditionalActionCell;
