// galeria zdjęć

import React, { FC, useState, ReactElement } from 'react';
import classnames from 'classnames';
import Slider, { CustomArrowProps } from 'react-slick';

import { IImage } from 'api/types';

import { ArrowSliderIcon } from 'assets/icons';

import styles from 'theme/pages/Product/components/Gallery/Gallery.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// typ danych wejściowych
interface IProps {
  images: IImage[];
}

const Gallery: FC<IProps> = ({ images }) => {
  // index aktualnego zdjęcia (z tablicy zdjęć)
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const PrevArrow = ({ ...props }: CustomArrowProps) => (
    <button {...props} className={classnames(styles.sliderArrow, styles.prevArrow)}>
      <ArrowSliderIcon />
    </button>
  );

  const NextArrow = ({ ...props }: CustomArrowProps) => (
    <button {...props} className={classnames(styles.sliderArrow, styles.nextArrow)}>
      <ArrowSliderIcon />
    </button>
  );

  const renderDots = (dots: ReactElement[]) => {
    return (
      <div className={styles.dots}>
        {dots.map((dot, i) => (
          <li
            className={classnames(styles.slideDot, {
              [styles.active]: dot.props.className === 'slick-active'
            })}
            key={i}>
            {dot.props.children}
          </li>
        ))}
      </div>
    );
  };

  const renderPaging = (i: number) => {
    return (
      <div className={styles.dot}>
        <img
          src={images[i].thumb}
          className={classnames(styles.image, {
            [styles.active]: i === currentImageIndex
          })}
        />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    vertical: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: renderDots,
    customPaging: renderPaging
  };

  return (
    <>
      <div
        className={classnames(
          styles.wrapperComponent,
          'StylePath-Pages-Product-components-Gallery'
        )}>
        <div className={styles['preview-image']}>
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                <img src={image.thumb} className={classnames(styles.image)} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Gallery;
