import React from 'react';
import classnames from 'classnames';

import { useGetHomeArticlesGroup } from 'api';

import styles from 'theme/pages/Home/components/KnowledgeBase/KnowledgeBase.module.scss';

export const HomeArticlesGroup = ({ articleGroupId }: { articleGroupId: string }) => {
  // pobranie listy aktualności
  const { data: homeHomeArticlesGroupData } = useGetHomeArticlesGroup(articleGroupId);

  return (
    <div
      id="knowledgeBase"
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Pages-Home-components-KnowledgeBase'
      )}>
      <h2 className={styles.title}>{homeHomeArticlesGroupData?.title}</h2>
      {homeHomeArticlesGroupData?.articles.map((article, index) => (
        <div key={index} className={classnames(styles.productWrapper, styles.mainProductWrapper)}>
          <div className={styles.message}>{article.title}</div>
        </div>
      ))}
    </div>
  );
};

export default HomeArticlesGroup;
