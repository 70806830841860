// pliki z tłumaczeniami

import enTranslations from './en.json';
import plTranslations from './pl.json';

export const resources = {
  en: {
    translation: enTranslations
  },
  pl: {
    translation: plTranslations
  }
};
